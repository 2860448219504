<!-- eslint-disable -->
<template>
	<div>
		<b-card>
      <h4 class="mb-2">
        &nbsp;&nbsp;&nbsp;{{ ($route.params.id != null) ? 'Edit Jenis Jabatan' : 'Tambah Jenis Jabatan' }}
      </h4>
			<div class="col-12">
        <div class="mb-1 row">
            <div class="col-sm-3">
                <label class="col-form-label" for="txtnama">Nama</label>
            </div>
            <div class="col-sm-9">
                  <input type="text" id="txtnama" class="form-control" v-model="ambilnilai.name" placeholder="Nama" required="">
            </div>
        </div> 
        <div class="mb-1 row">
            <div class="col-sm-3">
                <label class="col-form-label" for="cboTingkat">Tingkat</label>
            </div>
            <div class="col-sm-9">
                  <select class="select2 form-select form-control" id="cboTingkat" v-model="ambilnilai.level">
                    <option value="area">Area Layanan</option>
                    <option value="project">Project</option>
                    <option value="pusat">Pusat</option>
                    <option value="region">Region</option>
                    <option value="ulp">U L P</option>
                    <option value="kantorjaga">Kantor Jaga</option>
                  </select>
            </div>
        </div>
      </div>
      <h6 class="py-1 mx-1 mb-0 font-medium-2">
          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-lock font-medium-3 me-25"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 10 0v4"></path></svg>
          <span class="align-middle"> Notifikasi dan Persetujuan</span>
      </h6>
            <table class="table table-sm table-bordered b-table" style="width: 100%;">
                <thead>
                  <tr>
                    <th>MODUL</th>
                    <th>NOTIFIKASI</th>
                    <th>PERSETUJUAN</th>
                  </tr>
                </thead>
                <tbody v-for="(i, index) in ambilnilai.settings" :key="index">
                <tr>
                  <td>{{ i.name }}</td>
                  <td>
                    <b-form-checkbox
                        v-model="i.notification"
                      >
                    </b-form-checkbox>
                  </td>
                  <td>
                    <b-form-checkbox
                      v-model="i.approval"
                      >
                    </b-form-checkbox>
                  </td>
                </tr>
                </tbody>
            </table>
          <hr>
          <button type="button" @click.prevent="saveData()" :disabled="loading" class="btn btn-primary mt-1 mr-1">
            <span v-if="loading">
              <b-spinner small />
              Loading...
            </span>
            <span v-else>Simpan</span>
          </button>
          <router-link :to="{ name:'jabatan' }" class="btn btn-outline-secondary mt-1">Batal</router-link>
		</b-card>
	</div>
</template>
<script>
import {BCard, BFormCheckbox, BSpinner} from 'bootstrap-vue'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
export default {
  components: {
    BCard,
    BSpinner,
    BFormCheckbox
  },
  data(){
	return {
    loading :false,
		dataModule: [],
    ambilnilai: {
        name:null,
        level:'area',
        settings:[]
    },
    ambilnilaiEdit:null
	}
  },
  mounted(){
    this.getModule()
  },
  methods:{
    getModule(){
      if(this.$route.params.id == null){
        this.$http.get('/job-title/module').then(res =>{
        this.dataModule = res.data.data
        for(var nilai in this.dataModule){
              this.ambilnilai.settings.push({
                    module_id:this.dataModule[nilai].id,
                    name:this.dataModule[nilai].name,
                    approval:this.dataModule[nilai].approval,
                    notification:this.dataModule[nilai].notification
                }
              )
            }
          })
      }else{
        this.$http.get('/job-title/'+this.$route.params.id).then(res =>{
        this.ambilnilai.name = res.data.name
        this.ambilnilai.level = res.data.level
        this.dataModule = res.data.settings
        for(var nilai in this.dataModule){
              this.ambilnilai.settings.push({
                    module_id:this.dataModule[nilai].id,
                    name:this.dataModule[nilai].name,
                    approval:this.dataModule[nilai].approval,
                    notification:this.dataModule[nilai].notification
                }
              )
            }
          })
      }
    },
    saveData(){
      if(this.$route.params.id == null){
        this.$http.post('/job-title', this.ambilnilai).then(()=>{
                this.successSubmit()
              })
              .catch(err=>{
                this.errorSubmit(err)
              })
              .finally(() => {this.loading = false})
      }else{
        this.$http.put('/job-title/'+this.$route.params.id, this.ambilnilai).then(()=>{
                this.successSubmit()
              })
              .catch(err=>{
                this.errorSubmit(err)
              })
              .finally(() => {this.loading = false})
      }
    },
    successSubmit(){
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Submit Success',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      })
      
      this.$router.push({ name:this.$route.meta.pageActive })
    },
    errorSubmit(err){
      const msg = err.response.data.message
      this.$bvToast.toast((msg)?msg:'Submit error', {
        title: 'Error',
        solid: true,
        variant:'danger'
      })
    },
  }
}
</script>